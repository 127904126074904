import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { EnrollSessionService } from '../enrollSession.service';
import { BASE_URL } from 'app/constants';
import { CalculateRetailInitialOrderService } from '../calculateRetailInitialOrder.service';
import { CalculateRetailSmartOrderService } from '../CalculateRetailSmartOrder.service';
@Component({
    selector: 'app-order-sub-item',
    templateUrl: './Orders-Sub-Item.component.html',
    styleUrls: ['./Orders-Sub-Item.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class OrderSubItemComponent implements OnInit {
    customersForm: FormGroup;
    dialogTitle: string;
    action: string;
    userDetails: any;
    DynamikKitItem: any = [];
    SmartOrders: any[] = [];
    selectedCartItem: any;
    customerType: string;
    item: any;
    DynamikItems: any = [];
    type:any;
    qty:any;
    siteType:any;
    constructor(
        public matDialogRef: MatDialogRef<OrderSubItemComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _sharedMethodService: SharedMethodService ,
        private _toastr: ToastrService,
        public _enrollSession: EnrollSessionService,
        private _calculateRetailInitialOrder: CalculateRetailInitialOrderService,
        private _calculateRetailSmartOrder: CalculateRetailSmartOrderService
    ) {
        // Set the defaults
        this.action = _data.action;
        // Set the private defaults
        this.dialogTitle = this._data.item.Description;
        this.item = this._data.item;
        console.log("this.item",this.item);
        if(this._data.type){
          this.type=this._data.type;
        }
        else{
            this.type=1;
        }
        if(sessionStorage.getItem("siteType")=="ultlifestyle")
        {
            for(let kitItems of this.item)
            {
                var customKitItems =[];
                for (let kitItem of kitItems.KitMembers) {
                if (kitItem.kitMemberItemsField.length > 1) {
                    let customKitCart = _.find(kitItem.kitMemberItemsField,
                        (item: any) => {
                            if (item.itemCodeField == '2001-BB' || item.itemCodeField == '2261' || item.itemCodeField == '2001-BB-C') {
                                return item;
                            }
                        });
                    if (customKitCart) {
                        let customKitCartIndex = kitItem.kitMemberItemsField.indexOf(customKitCart);
                        customKitItems.push(kitItem.kitMemberItemsField[customKitCartIndex].itemCodeField);
                    } else {
                        customKitItems.push(kitItem.kitMemberItemsField[1].itemCodeField);
                    }
                } else {
                    customKitItems.push('0');
                }
              }
              this.DynamikItems[kitItems.ItemCode] = customKitItems; 
            }
        }
        else{
            for (const item of this.item.KitMembers) {
                if (item.kitMemberItemsField.length > 1) {
                    let kitItem = item.kitMemberItemsField.find(x => x.itemCodeField == '2001-BB'  || x.itemCodeField == '9827' || x.itemCodeField=='2001-BB-C' || x.itemCodeField=='2261');
                    if(kitItem)
                     {
                        this.DynamikItems.push(kitItem.itemCodeField);
                     }
                     else
                     {
                        this.DynamikItems.push(item.kitMemberItemsField[0].itemCodeField);
                     }
    
                }  else {
                    this.DynamikItems.push('0');
                }
    
            }
        }

        this.selectedCartItem = JSON.parse(localStorage.getItem('SmartOrderItems')) || [];
    }



    isItemRestrcited(product, selectedItems): any {
        let isRestricted = false;
        let filterItem: any;
        filterItem = _.find(selectedItems, (item) => { 
            return item.ItemCode === product.ItemCode; 
        });
        if (product.RestrictQty && product.RestrictQty > 0 && (filterItem && filterItem.Quantity >= product.RestrictQty)) {
            isRestricted = true;
        }
        return isRestricted;
    }
    
    ngOnInit(): void {
        this.siteType= sessionStorage.getItem("siteType");
        window.scrollTo(0, 0);
        if(this.type==1){
            if (this.siteType!="ultlifestyle") {
                this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
            }
            this._sharedMethodService.subOrderItem = true;
        }
        if (this.siteType!="ultlifestyle") {
            this._enrollSession.data.RetailOrderSummary.InitialOrders = this._enrollSession.data.RetailOrderSummary.InitialOrders || [];
            this._enrollSession.data.RetailOrderSummary.SmartOrders = this._enrollSession.data.RetailOrderSummary.SmartOrders || [];
        }
    }
    
    InserDynamicKitItemNew(): any {
        if (this.siteType!="ultlifestyle") {
            for (const [index, kitmember] of this.item.KitMembers.entries()) {
                if (kitmember.kitMemberItemsField.length > 1) {
                    for (const key of kitmember.kitMemberItemsField.keys()) {
                        if (kitmember.kitMemberItemsField[key].itemCodeField === this.DynamikItems[index]) {
                            this.DynamikKitItem.push(kitmember.kitMemberItemsField[key]);
                            break;
                        }
                    }
                }
            }
            if (!this.isItemRestrcited(this.item, this.selectedCartItem)) {
                const subitem = this.item;
                // This for Parent item fetch
                const itemObj3 = {
                    ItemCode: this.item.ItemCode,
                    Description: this.item.Description,
                    Quantity: 1,
                    Price: this.item.Price,
                    BusinessVolume: this.item.BusinessVolume,
                    ParentId: this.item.ItemCode,
                    IsUSOnly: this.item.IsUSOnly,
                    TinyImage: this.item.TinyPicture,
                    SmallPicture: this.item.SmallPicture,
                    LongDetail: this.item.LongDetail,
                    LongDetail2: this.item.LongDetail2,
                    LongDetail3: this.item.LongDetail3,
                    LongDetail4: this.item.LongDetail4,
                    Other1Price: this.item.Other1Price
                };
                this._sharedMethodService.ContinueRetailOrder(itemObj3, 1, this.type)
    
                // This is for Multiple sub items
                this.DynamikKitItem.forEach((item) => {
                    const itemObj = {
                        ItemCode: item.itemCodeField,
                        Description: item.descriptionField,
                        Quantity: 1,
                        Price: 0,
                        BusinessVolume: 0,
                        ParentId: subitem.ItemCode,
                        IsUSOnly: subitem.IsUSOnly,
                        TinyPicture: 'https://purium-api-dev.wsicloud.com/api/NonProfitEnrollment/GetImageData?fileName=' + item.itemCodeField + '.png',
                        SmallPicture: 'https://purium-api-dev.wsicloud.com/api/NonProfitEnrollment/GetImageData?fileName=' + item.itemCodeField + '.png',
                        LongDetail: item.LongDetail,
                        LongDetail2: item.LongDetail2,
                        LongDetail3: item.LongDetail3,
                        LongDetail4: item.LongDetail4,
                        Other1Price: item.Other1Price
                    };
                    this._sharedMethodService.ContinueRetailOrder(itemObj, 1, this.type);
        
                });
    
                this.item.KitMembers.forEach((item) => {
                    if (item.kitMemberItemsField.length === 1) {
                        // This for single item fetch            
                        const singleItem = item.kitMemberItemsField[0];
                        const itemObj2 = {
                            ItemCode: singleItem.itemCodeField,
                            Description: singleItem.descriptionField,
                            Quantity: 1,
                            Price: 0,
                            BusinessVolume: 0,
                            ParentId: subitem.ItemCode,
                            IsUSOnly: subitem.IsUSOnly,
                            TinyPicture: 'https://purium-api-dev.wsicloud.com/api/NonProfitEnrollment/GetImageData?fileName=' + singleItem.itemCodeField + '.png',
                            SmallPicture: 'https://purium-api-dev.wsicloud.com/api/NonProfitEnrollment/GetImageData?fileName=' + singleItem.itemCodeField + '.png',
                            LongDetail: singleItem.LongDetail,
                            LongDetail2: singleItem.LongDetail2,
                            LongDetail3: singleItem.LongDetail3,
                            LongDetail4: singleItem.LongDetail4,
                            Other1Price: item.Other1Price
                        };
                        this._sharedMethodService.ContinueRetailOrder(itemObj2, 1, this.type);
                    }
                  
                    this.matDialogRef.close({ event: 'submit' });
                });
                if(this._enrollSession.data.RetailOrderSummary.SmartOrders.length > 0){
                    this._calculateRetailSmartOrder.calculate(this._sharedMethodService.finalShippingMethod);
                }
                if(this._enrollSession.data.RetailOrderSummary.InitialOrders.length > 0){
                    this._calculateRetailInitialOrder.calculate(this._sharedMethodService.finalShippingMethod);
                }
                this._sharedMethodService.toggleSidenavforaddtocart();
            } else {
                this._toastr.error('Info', 'You can buy only ' + this.item.RestrictQty + ' quantity of this item.');
            }
        }
        else
        {
            this._enrollSession.data.RetailOrderSummary.SmartOrders = [];
            for (const items of this.item) {
                for (const [index, kitmember] of items.KitMembers.entries()) {
                    if (kitmember.kitMemberItemsField.length > 1) {
                        for (const key of kitmember.kitMemberItemsField.keys()) {
                            if (kitmember.kitMemberItemsField[key].itemCodeField === this.DynamikItems[items.ItemCode][index]) {
                                kitmember.kitMemberItemsField[key]['parentItemCode'] = items.ItemCode;
                                this.DynamikKitItem.push(kitmember.kitMemberItemsField[key]);
                                break;
                            }
                        }
                    }
                }
            }

            this.item.forEach((item) => {
                if (!this.isItemRestrcited(item, this.selectedCartItem)) {
                    const subitem = item;
                    // This for Parent item fetch
                    const itemObj3 = {
                        ItemCode: item.ItemCode,
                        Description: item.Description,
                        Quantity: 1,
                        Price: item.Price,
                        Other1Price: item.Other1Price,
                        BusinessVolume: item.BusinessVolume,
                        ParentId: item.ItemCode,
                        IsUSOnly: item.IsUSOnly,
                        TinyPicture: item.TinyPicture,
                        SmallPicture: item.SmallPicture,
                        LongDetail: item.LongDetail,
                        LongDetail2: item.LongDetail2,
                        LongDetail3: item.LongDetail3,
                        LongDetail4: item.LongDetail4
                    };
                    if (item.ItemCode == "11244" || item.ItemCode == "11245" || item.ItemCode == "11246" || item.ItemCode == "11247" || item.ItemCode=='11249' || item.ItemCode=='11281') {
                        this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
                        this._sharedMethodService.ContinueRetailOrder(itemObj3, 1, this.type);
                        this._sharedMethodService.toggleSidenavforaddtocart();
                    }
                    if (item.ItemCode == "11254" || item.ItemCode == "11264" || item.ItemCode == "11255" || item.ItemCode == "11265" ||
                        item.ItemCode == "11256" || item.ItemCode == "11266" || item.ItemCode == "11257" || item.ItemCode == "11267" || item.ItemCode == "11259" || item.ItemCode == "11269" || item.ItemCode=='11283' || item.ItemCode=='11285') {
                        this._sharedMethodService.ContinueRetailOrder(itemObj3, 1, 3, (item.ItemCode == "11254" || item.ItemCode == "11255" || item.ItemCode == "11256" || item.ItemCode == "11257" || item.ItemCode == "11259" || item.ItemCode=='11283' ? "2Month" : "4Month"));
                        this._sharedMethodService.toggleSidenavforaddtocart();
                    }


                    // This is for Multiple sub items
                    this.DynamikKitItem.forEach((kititem) => {
                        if (kititem.parentItemCode == subitem.ItemCode) {
                            const itemObj = {
                                ItemCode: kititem.itemCodeField,
                                Description: kititem.descriptionField,
                                Quantity: 1,
                                Price: 0,
                                BusinessVolume: 0,
                                ParentId: subitem.ItemCode,
                                IsUSOnly: subitem.IsUSOnly,
                                TinyPicture: BASE_URL + '/api/NonProfitEnrollment/GetImageData?fileName=' + kititem.itemCodeField + '.png',
                                SmallPicture: BASE_URL + '/api/NonProfitEnrollment/GetImageData?fileName=' + kititem.itemCodeField + '.png',
                                LongDetail: kititem.LongDetail,
                                LongDetail2: kititem.LongDetail2,
                                LongDetail3: kititem.LongDetail3,
                                LongDetail4: kititem.LongDetail4
                            };
                            if (item.ItemCode == "11244" || item.ItemCode == "11245" || item.ItemCode == "11246" || item.ItemCode == "11247" || item.ItemCode=='11249' || item.ItemCode=='11281') {
                                this._sharedMethodService.ContinueRetailOrder(itemObj, 1, this.type);
                                this._sharedMethodService.toggleSidenavforaddtocart();
                            }
                            if (item.ItemCode == "11254" || item.ItemCode == "11264" || item.ItemCode == "11255" || item.ItemCode == "11265" ||
                                item.ItemCode == "11256" || item.ItemCode == "11266" || item.ItemCode == "11257" || item.ItemCode == "11267" || item.ItemCode == "11259" || item.ItemCode == "11269" || item.ItemCode=='11283' || item.ItemCode=='11285') {
                                this._sharedMethodService.ContinueRetailOrder(itemObj, 1, 3, (item.ItemCode == "11254" || item.ItemCode == "11255" || item.ItemCode == "11256" || item.ItemCode == "11257" || item.ItemCode == "11259" || item.ItemCode=='11283'  ? "2Month" : "4Month"));
                                this._sharedMethodService.toggleSidenavforaddtocart();
                            }
                        }
                    });

                    item.KitMembers.forEach((item) => {
                        if (item.kitMemberItemsField.length === 1) {
                            // This for single item fetch            
                            const singleItem = item.kitMemberItemsField[0];
                            const itemObj2 = {
                                ItemCode: singleItem.itemCodeField,
                                Description: singleItem.descriptionField,
                                Quantity: 1,
                                Price: 0,
                                BusinessVolume: 0,
                                ParentId: subitem.ItemCode,
                                IsUSOnly: subitem.IsUSOnly,
                                TinyPicture: BASE_URL + '/api/NonProfitEnrollment/GetImageData?fileName=' + singleItem.itemCodeField + '.png',
                                SmallPicture: BASE_URL + '/api/NonProfitEnrollment/GetImageData?fileName=' + singleItem.itemCodeField + '.png',
                                LongDetail: singleItem.LongDetail,
                                LongDetail2: singleItem.LongDetail2,
                                LongDetail3: singleItem.LongDetail3,
                                LongDetail4: singleItem.LongDetail4
                            };
                            if (subitem.ItemCode == "11244" || subitem.ItemCode == "11245" || subitem.ItemCode == "11246" || subitem.ItemCode == "11247" || subitem.ItemCode=='11249' || item.ItemCode=='11281') {
                                this._sharedMethodService.ContinueRetailOrder(itemObj2, 1, this.type);
                            }
                            if (subitem.ItemCode == "11254" || subitem.ItemCode == "11264" || subitem.ItemCode == "11255" || subitem.ItemCode == "11265" ||
                                subitem.ItemCode == "11256" || subitem.ItemCode == "11266" || subitem.ItemCode == "11257" || subitem.ItemCode == "11267" || subitem.ItemCode == "11259" || subitem.ItemCode == "11269" || item.ItemCode=='11283' || item.ItemCode=='11285') {
                                this._sharedMethodService.ContinueRetailOrder(itemObj2, 1, 3, (subitem.ItemCode == "11254" || subitem.ItemCode == "11255" || subitem.ItemCode == "11256" || subitem.ItemCode == "11257" || subitem.ItemCode == "11259" || item.ItemCode=='11283' ? "2Month" : "4Month"));
                            }
                        }
                        this.matDialogRef.close({ event: 'submit' });
                    });
                } else {
                    this._toastr.error('Info', 'You can buy only ' + item.RestrictQty + ' quantity of this item.');
                }
            });
        }
    }  
    onClick() {
        this.matDialogRef.close({event:'close'});
        this._sharedMethodService.subOrderItem = false;
    }  
    onChange(newValue,index,itemcode) {
        if (this.siteType!="ultlifestyle") {
        this.DynamikItems[index]=newValue;
        }
        else{
            this.DynamikItems[itemcode][index]=newValue;
        }
        
    }
}
