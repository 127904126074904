// my-loader.component.ts
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { RestService } from 'app/shared/rest.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { API_URL, IMAGE_URL } from 'app/constants';
import { CalculateRetailOrderService } from 'app/shared/calculateRetailOrder.service';
import { AuthService } from 'app/shared/auth.service';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})

export class HomePageComponent implements OnInit {
  @ViewChild('slickModal', { static: false }) slickModal: SlickCarouselComponent;
  @ViewChild('slickModal1', { static: false }) slickModal1: SlickCarouselComponent;
  public slideConfig = {
    "dots": false,
    "infinite": true,
    "speed": 500,
    "slidesToShow": 1,
    "adaptiveHeight": false,
    "autoplay": true,
    "autoplaySpeed": 5000,
    "dir": 'ltr',
    "arrows": false
  };
  IMAGE_URL: string;
  banners: any = [];
  PuriumNewestBanner: any = [];
  webalias: string;
  giftcardName: string;
  location: Location;
  mobileView: any;
  sharelinkURL:any;
  constructor(
    private _restService: RestService,
    private router: Router,
    private _location: Location,
    public _enrollSession: EnrollSessionService,
    private _activateRoute: ActivatedRoute,
    private _toastr: ToastrService,
    public _auth: AuthService,
    public _sharedMethodService: SharedMethodService,
    public _calculateRetailOrderService: CalculateRetailOrderService,
  ) {
    this.IMAGE_URL = IMAGE_URL;
    let bioMedic3 = sessionStorage.getItem("FromBioMedic3Site");
    this._enrollSession.data.customertype = this._enrollSession.data.customertype ? this._enrollSession.data.customertype : 22;
    this.location = this._location;
    this.sharelinkURL = sessionStorage.getItem('FromShareLinkCart');
    if ((this._enrollSession.editTransformationPack != 'true' && !this._enrollSession.data.IsLoggedIn) && !this._enrollSession.data?.RetailOrderSummary && bioMedic3 === 'false') {
      this._enrollSession.data = {};
    }
    if (this._enrollSession.data && this._enrollSession.editTransformationPack != 'true' && Object.keys(this._enrollSession.data).length === 0 && bioMedic3 === 'false') {
      this._enrollSession.data.IsShowCart = false;
      this._enrollSession.data.page = this.location.path();
      this._enrollSession.data.TotalItemLength = 0;
      this._enrollSession.data.RetailEnroll = {};
      this._enrollSession.data.InitOrder = [];
      this._enrollSession.data.InitialOrdersSubTotal = 0;
      this._enrollSession.data.RetailOrderSummary = {};
      this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
      this._enrollSession.data.EligibleForFreeUpgrade = false;
      this._enrollSession.data.RetailEnroll.IsNonprofit = false;
      this._enrollSession.data.FileUploaded = false;
      this._enrollSession.data.RetailEnroll.ShippingMethod = '';
      this._enrollSession.data.usePrimaryCard = null;
      this._enrollSession.data.primaryCreditCardField = {};
      this._enrollSession.data.EnrollmentType = this._enrollSession.data.EnrollmentType || 1;
      this._enrollSession.data.IsUpGrade =
        this._activateRoute.snapshot.queryParamMap.get('customerId') ? this._activateRoute.snapshot.queryParamMap.get('customerId') : false;
      if (this._enrollSession.data.IsUpGrade) {
        localStorage.setItem('customerId', this._enrollSession.data.IsUpGrade);
      }
      this._enrollSession.data.Volume109 = 0;
      this._enrollSession.data.SmartOrderBVTotal = 0;
      this._enrollSession.data.InitialOrdersQVTotal = 0;
      this._enrollSession.data.AddOnOrdersQVTotal = 0;
    }
    var url = new URL(window.location.href);
    this.webalias = this._activateRoute.snapshot.queryParamMap?.get('giftcard') || url?.searchParams.get("giftcard") || sessionStorage.getItem('giftCardName');
  }
  ngOnInit(): void {
    if (this._auth.isLoggedIn()) {
      if (this._enrollSession.data.LoggedInUserData?.CustomerType==20 || this._enrollSession.data.LoggedInUserData?.CustomerType==22 ) {
        this.getImage(this._enrollSession.data.LoggedInUserData?.EnrollerID);
      } else {
        this.getImage(this._enrollSession.data.LoggedInUserData?.CustomerID);
      }
      this.getEnrollerDetails();
    } else {
      this.giftcardFunctionality();
    }
    let screenWidth = window.innerWidth
    if (screenWidth < 550) {
      this.mobileView = true;
    }
    else {
      this.mobileView = false;
    }
    this.banners = [
      { url: '../../../assets/images/home_img/carusel/carusel_1.png', text: 'Purium helped me COME HOME to myself after fifteen years of looking for solutions to address chronic pain. #phpLookAtMeNow (Mary Beth F)' },
      { url: '../../../assets/images/home_img/carusel/carusel_2.png', text: 'I created a lifestyle a year ago. Since then my health and fitness are steadily improving! I just found out during my trip to the hospital that my blood and cholesterol levels were great! Just last year it was high, almost at a dangerous level (all while being a fitness trainer).  I also found out that the arm I injured last week only ended up bruised because of the health of my joints, tendons, ligaments, and muscle tissue. #phpLiveWorkPlay (James O)' },
      { url: '../../../assets/images/home_img/carusel/carusel_3.png', text: 'We share because we care. #phpShareBecauseYouCare' },
      { url: '../../../assets/images/home_img/carusel/carusel_4.png', text: 'All in 90 days: Lost 40 pounds and 20 pounds more to go. Lowered A1C from 8.5 to below 6. I need new clothes. #phpLookAtMeNow (Koko J)' },
      { url: '../../../assets/images/home_img/carusel/carusel_5.png', text: `JAN 2022 - AUG 2022 #phpLookAtMeNow  (Alexis C)` },
      { url: '../../../assets/images/home_img/carusel/carusel_6.png', text: `Your kids will do what they see you do. While I was pregnant I drank tons of greens. Every morning she makes herself a Power Shake. I'm glad this is a habit that I helped create for my sweet pea. #phpLiveWorkPlay (Candice A)` },
      { url: '../../../assets/images/home_img/carusel/carusel_7.png', text: 'Sustainable change is impossible without a shift in mindset. #phpLookAtMeNow (Jackylyn K)' },
      { url: '../../../assets/images/home_img/carusel/carusel_8.png', text: 'Fueling the way in the Himalayas! (Tony R)' },
      { url: '../../../assets/images/home_img/carusel/carusel_9.png', text: '6 months apart, before and after the green superfood journey. Fatigue and sugar cravings are gone. (Vlad N.)' },
      { url: '../../../assets/images/home_img/carusel/carusel_10.png', text: 'My daughter loves MVP Purium (Ellie H.)' },
      { url: '../../../assets/images/home_img/carusel/carusel_11.png', text: `We've been in the car for 24 hours and let me tell you that this Organic Beet Juice can't be beat! I got out my portal blender and mixed the Purium Can't Beet This, then I shared it with the family as we finished the final stretch on our road trip to see my brother. It was the BEST! #phpLookAtMeNow (Nicole F.)` },
      { url: '../../../assets/images/home_img/carusel/carusel_12.png', text: `35+ lbs and many inches later… I am so proud of myself! it has taught me self discipline, PATIENCE & it's brought back happiness & comfortability that I forgot I even had. (Camille F.)` }
    ];
    this.PuriumNewestBanner = [
      { banner: '../../../assets/images/home_img/Pineal_ClearBanner.png',},
      { banner: '../../../assets/images/home_img/PuriumNewest.png',},
      { banner: '../../../assets/images/home_img/Meno-Positive Banner Final.png',},
    ];

    setTimeout(() => {
      $('.slick-prev').css({ 'background-color': '#417163' })
      $('.slick-next').css({ 'background-color': '#417163' })
      $('.slick-prev').css({ 'border-radius': '50%' })
      $('.slick-next').css({ 'border-radius': '50%' })
    }, 1000);
    this.onResize();

    const isIOS = /iPhone/.test(navigator.userAgent);

    if (isIOS) {
      const element = document.querySelector('.room-gallery-cta');
      element.classList.add('ios-background-fix');
    }
    this._calculateRetailOrderService.DiscountValue = '';
    this._calculateRetailOrderService.totalRemainingBalance = '';
    this._calculateRetailOrderService.totalRemainingBalanceForSmartOrder = '';
    this._calculateRetailOrderService.isSelected = false;
    localStorage.removeItem("SecureShipping");
  }

  redirectToProduct() {
    this.router.navigate(['/AllProducts'], { queryParams: { giftcard: this._enrollSession.data.webAlias } });
  }
  next() {
    this.slickModal.slickNext();
  }
  prev() {
    this.slickModal.slickPrev();
  }
  nextSlide() {
    this.slickModal1.slickNext();
  }
  prevSlide() {
    this.slickModal1.slickPrev();
  }

  getTextBannerButton(){
    $('.sliding1').on('beforeChange', function(event,slick,currentSlide, nextSlide){
      changeText(nextSlide);
  });
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    let decreaseWidth = (17.5 / 100) * $(window).width()
    $('.myIframe').css('width', ($(window).width() - decreaseWidth) + 'px');
    $('.myIframe').css('height', (173 / 100) * ($(window).width() - decreaseWidth) + 'px');

    $('.VideoPress').css('width', ($(window).width() - decreaseWidth) + 'px');
    $('.VideoPress').css('height', (67 / 100) * ($(window).width() - decreaseWidth) + 'px');
    if (window.innerWidth > 900) {
      $('.mainVideo').css('width', (48 / 100) * ($(window).width() - decreaseWidth) + 'px');
      $('.mainVideo').css('height', (28 / 100) * ($(window).width() - decreaseWidth) + 'px');
    }
    else {
      $('.mainVideo').css('width', ($(window).width() - decreaseWidth) + 'px');
      $('.mainVideo').css('height', (58 / 100) * ($(window).width() - decreaseWidth) + 'px');
    }
    if (window.innerWidth < 900) {
      $('.sqrImage').css('height', (70 / 100) * ($(window).width() - decreaseWidth) + 'px');
    }
    if (window.innerWidth < 900) {
      $('.ownerImg').css('height', (140 / 100) * ($(window).width() - decreaseWidth) + 'px');
    }
    if (window.innerWidth < 900) {
      $('.room-gallery-cta').css('height', (170 / 100) * ($(window).width() - decreaseWidth) + 'px');
    }
  }
  RedirectToUltLifestyle() {
    this.router.navigateByUrl('/Ultlifestyle');
  }
  isCustomerExist() {
    let customerType = [4, 5, 6, 7, 8, 10, 17, 19, 21, 24, 25];
    var LoggedInUser = JSON.parse(localStorage.getItem("LoggedInUserData")) || {};
    let customerFound = customerType.find((element) => element === LoggedInUser.CustomerType);
    return customerFound ? true : false;
  }
  isSsoLogin() {
    return sessionStorage.getItem("IsSSOLogin") == 'true' ? true : false;
  }
  getImage(id) {
    this._enrollSession.customerImage =  API_URL + "/NonProfitEnrollment/GetCustomerImageData?customerId=" + id || "../../../assets/images/avatars/profile_icon.png";
  }

  giftcardFunctionality() {
    if (this.webalias && this.webalias != '' && this.webalias != null && this.webalias != "null") {
      const request = '?WebAlias=' + this.webalias;
      this._restService.GetCustomerSite(request).subscribe((result: any) => {
        if (result.Data != null) {
          this._enrollSession.data.webAlias = this.webalias;
          sessionStorage.setItem('giftCardName', this._enrollSession.data.webAlias);
          this._enrollSession.data.RetailEnroll.webAlias = result.Data.firstNameField + ' ' + result.Data.lastNameField;
          this.getImage(result.Data.customerIDField);
        } else {
          this._toastr.error('Invalid WebAlias.', 'Error');
        }
      }, error => {
        this._toastr.error('Somthing is wrong Please try again', 'Error');
      });
    }
  }
  getEnrollerDetails()
  {
      let enrollerInfo =  JSON.parse(localStorage.getItem('EnrollerData'));
      let enrollername = enrollerInfo?.EnrollerFirstName + " " + enrollerInfo?.EnrollerLastName;
      return enrollername;
  }
  openCart(){
    if(window.innerWidth < 550){
      this._sharedMethodService.openMobileSummaryCart();
    }
    else{
      this._sharedMethodService.toggleSidenavforaddtocart();
    }
  }
}
function changeText(nextSlide: any) {
  if(nextSlide === 2){
    document.getElementById('textchange').textContent = 'Shop Meno-Positive   ';
    $('.bannerButton').addClass('collection-btn2');
    $('.bannerButton').removeClass('collection-btn');
    $('.bannerButton').removeClass('collection-btn1');
    $('#imageBannerArrow').attr("src","../../../assets/icons/BrownLineArrow.png");
    $('#imageBannerArrowMobile').attr("src","../../../assets/icons/BrownLineArrow.png");
    document.getElementById('imageBannerArrow').style.width = '50px';
    $('.color-grey').css('background-color','#ffffff');
  }
  if(nextSlide === 1){
    document.getElementById('textchange').textContent = 'Shop Essential Mushroom Blends   ';
    $('.bannerButton').addClass('collection-btn');
    $('.bannerButton').removeClass('collection-btn1');
    $('.bannerButton').removeClass('collection-btn2');
    $('#imageBannerArrow').attr("src","../../../assets/icons/YellowLineArrow.png");
    $('#imageBannerArrowMobile').attr("src","../../../assets/icons/YellowLineArrow.png");
    $('.color-grey').css('background-color','#EFEFF1');
  }
  if(nextSlide === 0){
    document.getElementById('textchange').textContent = 'Shop All Pineal Clear Products   ';
    $('.bannerButton').addClass('collection-btn1');
    $('.bannerButton').removeClass('collection-btn');
    $('.bannerButton').removeClass('collection-btn2');
    $('#imageBannerArrow').attr("src","../../../assets/icons/BlueLineArrow.png");
    $('#imageBannerArrowMobile').attr("src","../../../assets/icons/BlueLineArrow.png");
    document.getElementById('imageBannerArrow').style.width = '50px';
    $('.color-grey').css('background-color','#ffffff');
  }
}
